import React from "react"
import { graphql, Link } from "gatsby"
import uuid from "react-uuid"
import myInitObject from "../ultis/variable"
import shareFacebookIcon from "../../static/share_facebook.svg"
import shareLinkedinIcon from "../../static/share_linkedin.svg"
import shareTwitterIcon from "../../static/share_twitter.svg"
import linkedinAuthoreIcon from "../../static/linkedin_authore.png"
import Layout from "../components/layout"
import FeedLastBlog from "../components/feedLastBlog"
import TitleStyled from "../components/styles/titleStyles"
import BlogSinlgeStyled from "../components/styles/BlogSinlgeStyles"
import Builder from "../components/builder"
import SEO from "../components/seo"

const BlogTemplate = ({ data, pageContext }) => {
  const tmp = "/blog/" + pageContext.slug
  const pageContextTmp = { ...pageContext, slug: tmp }

  const {
    image,
    feedCategory,
    title,
    date,
    builder,
    author,
    metaTitle,
    metaDescription,
    metaImage,
  } = data.swapi.blogCollection[0]
  const facebookLinkShare = `https://www.facebook.com/sharer/sharer.php?display=page&u=${
    typeof window !== `undefined` ? window.location.href : ""
  }`
  const twitterLinkShare = `https://twitter.com/intent/tweet?source=sharethiscom&text=${title}&url=${
    typeof window !== `undefined` ? window.location.href : ""
  }`
  const linkedinLinkShare = `http://www.linkedin.com/shareArticle?mini=true&url=${
    typeof window !== `undefined` ? window.location.href : ""
  }&title=${title}&source=${
    typeof window !== `undefined` ? window.location.hostname : ""
  }`

  const name = author.name.replace(/ .*/, "")

  return (
    <Layout pageContext={pageContextTmp}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        img={metaImage ? metaImage.path : ""}
        lang="pl"
      />
      <BlogSinlgeStyled image={myInitObject.cockpitUrl + image.path}>
        <div className="singlePost__img"></div>
        <div className="container singlePost__container">
          <div className="container__category">
            <p className="singlePost__category">Kategorie:</p>
            {feedCategory.length
              ? feedCategory.map(category => {
                  const tmp = category.replace(/(^[\s]+|[\s]+$)/g, "")
                  return (
                    <a
                      href={`/blog/#${tmp}`}
                      key={uuid()}
                      className={`singlePost__category__item`}
                    >
                      {category}
                    </a>
                  )
                })
              : ""}
          </div>
          <div className="singlePost__title">
            <TitleStyled
              textAlign="center"
              linePosition="center"
              color="#000000"
            >
              <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
            </TitleStyled>
          </div>
          <div className="singlePost__meta">
            <p className="singlePost__author">{author.name},</p>
            <p className="singlePost__date">{date}</p>
          </div>
          <div className="singlePost__wrapper">
            <div className="singlePost__share">
              <div>
                <p>Udostępnij:</p>
                <a href={facebookLinkShare}>
                  <img src={shareFacebookIcon} alt="" />
                </a>
                <a href={linkedinLinkShare}>
                  <img src={shareLinkedinIcon} alt="" />
                </a>
                <a href={twitterLinkShare}>
                  <img src={shareTwitterIcon} alt="" />
                </a>
              </div>
            </div>
            <div className="singlePost__content">
              <Builder data={builder} pageContext={pageContext} />
            </div>
          </div>
          <div className="singlePost__authore">
            <div>
              <div className="singlePost__authore__data">
                <p className="singlePost__authore__name">{author.name}</p>
                <p className="singlePost__authore__postion">
                  {author.position}
                </p>
                <p className="singlePost__authore__email">{author.email}</p>
                {author.linkedin ? (
                  <p className="singlePost__authore__linkedin">
                    <a href={author.linkedin}>
                      <img src={linkedinAuthoreIcon} alt="linkedin" />
                    </a>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            {name === "Grzegorz" || name === "Marcin" ? (
              <Link to={`/about/#${name}`}>
                <img
                  src={myInitObject.cockpitUrl + author.image.path}
                  alt={author.name}
                />
              </Link>
            ) : (
              <img
                src={myInitObject.cockpitUrl + author.image.path}
                alt={author.name}
              />
            )}
          </div>
        </div>
      </BlogSinlgeStyled>
      <div className="container">
        <FeedLastBlog heading="Czytaj więcej" />
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogTemplateQuery($id: String!) {
    swapi {
      blogCollection(_id: $id) {
        image {
          path
        }
        slug
        feedCategory
        title
        date
        builder
        author {
          name
          position
          email
          linkedin
          image {
            path
          }
        }
        metaTitle
        metaDescription
        metaImage {
          path
        }
      }
    }
  }
`
