import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import myInitObject from "../ultis/variable"
import TitleStyled from "../components/styles/titleStyles"
import FeedStyled from "../components/styles/FeedStyles"
import FeedBox from "../components/feedBox"
import uuid from "react-uuid"

const FeedLastBlog = ({ heading }) => {
  const data = useStaticQuery(graphql`
    query {
      swapi {
        blogCollection(limit: 3) {
          feedCategory
          feedDescription
          feedTextButton
          slug
          feedImage {
            path
          }
          feedTitle
          date
          author {
            name
          }
        }
      }
    }
  `)
  const { blogCollection } = data.swapi
  const [selectedCategory, setSelectedCategory] = useState("")
  const [postList, setPostList] = useState([])
  let listCategory = []

  useEffect(() => {
    setPostList(blogCollection)
  }, [])

  blogCollection.map(category => {
    category.feedCategory.map(item => {
      if (!listCategory.includes(item.replace(/(^[\s]+|[\s]+$)/g, ""))) {
        listCategory.push(item.replace(/(^[\s]+|[\s]+$)/g, ""))
      }
    })
  })

  return (
    <FeedStyled>
      <section className="feed">
        <div className="container">
          <TitleStyled
            textAlign="center"
            linePosition="center"
            color="#000"
            lineColor="#000"
          >
            <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
          </TitleStyled>
        </div>
        <div className="container container__feed blog__feed--3">
          {blogCollection.length
            ? blogCollection.map(item => {
                return (
                  <div key={uuid()} className="feed__item">
                    <FeedBox
                      img={myInitObject.cockpitUrl + item.feedImage.path}
                      title={item.feedTitle}
                      descirption={item.feedDescription}
                      link={"/blog/" + item.slug}
                      // linkText={item.feedTextButton}
                      date={item.date}
                      author={item.author.name}
                      titleSize="22"
                    />
                  </div>
                )
              })
            : ""}
        </div>
      </section>
    </FeedStyled>
  )
}

export default FeedLastBlog
